import React from "react"
import Layout from "../components/Layout"
import { StaticQuery, graphql } from 'gatsby'
import Landing from '../route/Landing/index'

const Hello = (t) => {
  return (
    <h1>Hello!</h1>
  )
}

export default function Home({ location, blok }) {
  return (
    <Layout location={location} t="default">
      <Hello t="default" />
    </Layout>

  )
}